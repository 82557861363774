import React from 'react'
import { graphql } from 'gatsby'
import { Container, Content, Image, Section } from 'react-bulma-components'
import { AboutEnia, Bulmarkdown } from '../components'
import { formatDate, toEniaAssetURL } from '../helpers'
import { DefaultLayout as Layout } from '../layouts'

export const query = graphql`
  query($newsId: ID!) {
    enia {
      article: news_by_id(id: $newsId) {
        id
        date_published
        title
        excerpt
        body
        image {
          id
          filename_download
        }
      }
    }
  }
`

export default function article({ data }) {
  console.debug(JSON.stringify(data))
  const { article } = data.enia

  if (!article) {
    throw Error('Article not found')
  }

  return (
    <Layout
      pageContext={{
        frontmatter: {
          title: `${article.title} - Enia`,
          description: article.excerpt,
        },
      }}>
      <Section size='small' backgroundColor='primary'>
        <Container textAlign='center'>
          <h1 className='title is-1' style={{ color: 'white' }}>
            {article.title}
          </h1>
        </Container>
      </Section>
      <Section size='medium'>
        <Container>
          {Boolean(article.image) && (
            <Image
              src={toEniaAssetURL(data.enia.article.image)}
              alt={article.image.filename_download}
              style={{
                width: '32em',
                maxWidth: 'calc(100vw - 32px)',
                float: 'right',
                paddingLeft: 32,
                paddingBottom: 32,
              }}
            />
          )}
          <Content>
            <Bulmarkdown>{article.body}</Bulmarkdown>
            <p style={{ color: '#999' }}>
              —<br />
              Article du{' '}
              <time>{formatDate(article.date_published, { day: 'numeric', month: 'long', year: 'numeric' })}</time>
            </p>
          </Content>
          <AboutEnia />
        </Container>
      </Section>
    </Layout>
  )
}
